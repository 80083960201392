import { TokenStatusEnum } from '@amaris/lib-highway/enums';
class ConfigurationModel {}
class HttpOptionsModel {
  constructor() {
    this.observe = 'events';
  }
}
class HttpCacheModel {}
class MatchConfig {
  constructor(init) {
    this.listenOn = window;
    Object.assign(this, init);
  }
}
class TokenModel {
  constructor(token, sub, picture, culture, roles, userName, firstName, lastName, email) {
    this.token = token;
    this.culture = culture;
    this.roles = roles;
    this.userName = userName;
    this.firstName = firstName;
    this.lastName = lastName;
    this.status = TokenStatusEnum.Ok;
    this.sub = sub;
    this.picture = picture;
    this.email = email;
  }
}
class LabelValueModel {}
class TableData {}

/**
 * Generated bundle index. Do not edit.
 */

export { ConfigurationModel, HttpCacheModel, HttpOptionsModel, LabelValueModel, MatchConfig, TableData, TokenModel };
